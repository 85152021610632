.about-header {
  color: #cb9737;
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
  margin-top: 7%;
  margin-bottom: 7%;
}

.about {
  background-color: #007663;
  font-family: "Roboto", sans-serif;
  text-justify: distribute;
  margin: 30px;
  padding: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wojtek {
  height: 150px;
}

.trophy {
  height: 70px;
}

.achievements {
  margin: 10px;
  padding: 20px;
  color: #007663;
}

.treningi-header {
  color: #007663;
  margin-top: 2%;
}

.treningi > div > ul {
  color: #007663;
  text-align: left;
  margin: 15px;
}

.moreinfo {
  padding-bottom: 10%;
}

.contact-info {
  background-color: #007663;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details > p {
  text-align: left;
}

.contact-info > h3 {
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: x-large;
}

.icon {
  height: 20px;
  width: auto;
}

.footer {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  background-color: #004535;
}

.footer p {
  padding-top: 1.5%;
  font-size: smaller;
  color: white;
}

.footer a {
  text-decoration: none;
  color: white;
}

@media only screen and (min-width: 1024px) {
  .about {
    margin: 50px;
    padding: 50px;
    font-size: 20px;
  }
  .about-header {
    font-size: 40px;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .trophy {
    height: 100px;
  }
  .achievements {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 140px;
    padding-right: 140px;
  }
  .treningi {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .contact-details p {
    font-size: 20px;
  }
  .contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .map {
    width: 450px;
    height: 300px;
  }
}
