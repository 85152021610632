.main-logo {
  height: 10rem;
  width: auto;
}

@media only screen and (min-width: 1024px) {
  .main-logo {
    height: 15rem;
    width: auto;
    padding-bottom: 2%;
  }
}
