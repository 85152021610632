@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.navbar .navbar-toggler {
  border-style: none;
  background-color: white;
}

.nav-color {
  background-color: white;
}

.links {
  margin-left: 35px;
  margin-right: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
  font-size: larger;
}
