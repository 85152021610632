.carousel {
    margin-top: 3%;
    font-family: 'Roboto', sans-serif;
}

.carousel-caption > h3 {
    background:rgba(122, 120, 120, 0.4);
}

@media only screen and (max-width: 1024px) {
    .carousel-caption > h3 {
        font-size: 20px;
        font-weight: normal;
    }
}



@media only screen and (min-width: 1024px) {
    .carousel {
        margin-top: 0.5%;
    }
  }