#example-collapse-text {
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin: 30px;
  padding: 20px;
}

@media only screen and (min-width: 1024px) {
  #example-collapse-text {
    margin: 30px;
    padding: 20px;
    max-width: 600px;
  }
}
