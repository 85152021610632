.jumbotron {
  background-color: #007663 !important;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.jumbo-header {
  text-align: center;
  font-weight: bolder;
}

.jumbo-container {
  padding-right: 35px;
  padding-left: 35px;
}

@media only screen and (max-width: 1024px) {
  .wojtek-foto {
    height: 200px;
    padding: 15px;
  }
  .jumbo-container {
    text-align: left;
    padding-top: 20px;
  }  
}


@media only screen and (min-width: 1024px) {
  .jumbo-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }
  p {
    font-size: larger;
  }
  .wojtek-foto {
    height: 320px;
    padding: 25px;
  }
}
